import {
  Component,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  Chart
} from 'chart.js';
import {
  stat
} from 'fs/promises';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements AfterViewInit {
  @ViewChild('donutChart') donutChart!: ElementRef;
  @Input('data') data: any;
  @Output('onClickStatus') onClickStatusEvent = new EventEmitter();

  public successCount: number = 0;
  public failureCount: number = 0;
  public inProgressCount: number = 0;

  public statusList = ['Success', 'In Progress', 'Failure']
  public failureList = ['Failure', 'Warning','Validation Extraction - Failure', 'Validation BR - Failure', 'Validation Preliminary - Failure', 'Validation Catalog - Failure']
  public inProgressList = ['In Progress', 'Pending', 'Validation Extraction - Pending', 'Validation BR - Pending', 'Validation Preliminary - Pending', 'Validation Catalog - Pending']
  public successList = ['Success', 'Partial Success']

  public backgroundColors = ['#28a745', '#007bff', '#dc3545']
  public hoverColors = ['#078724', '#00356d', '#a90918']
  
  public indexSelected = null;

  constructor() {}


  ngOnInit(): void {
    // Inicialización adicional si es necesario
    this.successCount += this.data
    .filter(el => this.successList.includes(el.statusname)) // Filtras los elementos que cumplen con la validación
    .reduce((acc, el) => acc + (el.count || 0), 0);

    this.failureCount += this.data
      .filter(el => this.failureList.includes(el.statusname)) // Filtras los elementos que cumplen con la validación
      .reduce((acc, el) => acc + (el.count || 0), 0);
      
    this.inProgressCount += this.data
    .filter(el => this.inProgressList.includes(el.statusname)) // Filtras los elementos que cumplen con la validación
    .reduce((acc, el) => acc + (el.count || 0), 0);

  }

  ngAfterViewInit(): void {
    const ctx = this.donutChart.nativeElement.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: this.statusList,
        datasets: [{
          data: [this.successCount, this.inProgressCount, this.failureCount], // Datos de ejemplo
          backgroundColor: this.backgroundColors, // Colores de las secciones
        }]
      },
      options: {
        title: {
          display: true,
          text: 'Document Status',
          fontSize: 18,
        },
        onClick: (e, el) => {
          let datasetIndex;
          let dataset;

          let index = el[0]._index;
          datasetIndex = el[0]._datasetIndex;
          dataset = chart.data.datasets[datasetIndex];
          dataset.backgroundColor = this.backgroundColors.slice();
          dataset.hoverBackgroundColor = this.hoverColors.slice();

          switch(index) {
            case 0:
              if (this.indexSelected == index) {
                dataset.backgroundColor[index] = '#28a745'; 
                dataset.hoverBackgroundColor[index] = '#28a745';
                this.indexSelected = null;    
              } else {
                dataset.backgroundColor[index] = '#14e644'; 
                dataset.hoverBackgroundColor[index] = '#14e644';    
                this.indexSelected = index;
              }
              break;
            case 1:
              if (this.indexSelected == index) {
                dataset.backgroundColor[index] = '#007bff'; 
                dataset.hoverBackgroundColor[index] = '#007bff';    
                this.indexSelected = null;    
              } else {
                dataset.backgroundColor[index] = 'blue'; 
                dataset.hoverBackgroundColor[index] = 'blue';  
                this.indexSelected = index;
              }
              break;
            case 2:
              if (this.indexSelected == index) {
                dataset.backgroundColor[index] = '#dc3545'; 
                dataset.hoverBackgroundColor[index] = '#dc3545';   
                this.indexSelected = null;    
              } else {
                dataset.backgroundColor[index] = '#ff0000'; 
                dataset.hoverBackgroundColor[index] = '#ff0000';  
                this.indexSelected = index;
              }
              break;
            default:
              break;
          }

          let status = this.statusList.filter((status, index) => index == el[0]._index)[0];
          this.onClickStatusEvent.emit(status)
          chart.update();
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'left',
          labels: {
            generateLabels: (chart) => {
              const dataset = chart.data.datasets[0];
              return chart.data.labels.map((label, index) => {
                const value = dataset.data[index].toLocaleString(); // Formatear con separadores de miles
                return {
                  text: `${label}: ${value}`, // Mostrar el valor formateado
                  fillStyle: dataset.backgroundColor[index],
                };
              });
            },
            padding: 20
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const value = dataset.data[tooltipItem.index].toLocaleString(); // Formatear con separadores de miles
              const label = data.labels[tooltipItem.index];
              return `${label}: ${value}`; // Mostrar valor formateado en el tooltip
            }
          }
        }
      }
    });
  }
}