import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';

import * as moment from 'moment';
import {
  Subject
} from 'rxjs';
import {
  D3BarChartBrushedComponent
} from 'src/app/components/graphs/barchart-brushed/barchart-brushed.component';
import {
  PaginationModel
} from 'src/app/models/pagination';
import {
  TranslatePipe
} from 'src/app/pipes/translate.pipe';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  GlobalService
} from 'src/app/services/global.service';
import {
  LinkService
} from 'src/app/services/link.service';
import {
  PaginationService
} from 'src/app/services/pagination.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  @ViewChild('datesbarchart') datesbarchart: D3BarChartBrushedComponent;

  public pagesProcessed: number
  public documentsProcessed: number
  public processedByEXT: number
  public processedByCLA: number
  public processedByVAL: number

  public documentTypesData: any
  public documentTypesTotalCount: number

  public validationErrorTypesData: any
  public validationErrorTypesTotalCount: number

  public statusData: any[] = []
  public errorNumberByMonths: any
  public documentsLoading: boolean = true;

  // Definir un array de nombres de clases
  public chartClasses = ['bg-primary', 'bg-success', 'bg-danger', 'bg-warning', 'bg-info'];

  public settingTable: any;
  public columns: any[];
  public rows: any = {};

  public first_date: Date = new Date();
  public last_date: Date = new Date();

  public start_date: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
  public end_date: Date = new Date();

  public dataLoading: Boolean = true;
  public dateSearch: Boolean = false;

  public showFilters = false;
  public filters = {};
  public labelFilters = [];
  public statusList: any;
  public datamodelList: any[];

  private pagination: PaginationModel;

  //Property to emit filter to delete in the validations component
  public filterEvent: Subject < string > = new Subject < string > ();
  public validationErrorsList: any;
  public aiAgentsList: {
    name: string;
  } [];
  public selectedFilterVal: boolean = false;
  public selectedFilterExt: boolean = false;
  public selectedFilterCla: boolean = false;
  public selectDatamodelFilter: boolean = false;
  public datamodelNameFilter: any = '';
  public validationNameFilter: any = '';
  public selectValidationFilter: boolean = false;
  public selectedAnyAgentFilter: boolean = false;

  constructor(
    private apiService: ApiService,
    private linkService: LinkService,
    private paginationService: PaginationService,
    private globalService: GlobalService,
    private translate: TranslatePipe,
  ) {

    this.getColumns()
    this.getSettingsTable()
  }

  ngOnInit() {

    // Inicialización adicional si es necesario
    this.pagination = this.paginationService.getDefaultPagination('documents');
    this.getDashboardCardsInfo()
    this.getDocumentsError()
    this.getValidationErrorTypes()
    this.getStatusData()
    this.getDocumentsByDatamodel()
    this.getDocumentsTableInfo()
    this.getValidationErrorsList()

    this.statusList = this.globalService.getGlobalStatus();
    this.datamodelList = Array.from(
      this.globalService.getDatamodels().values()
    );
    this.aiAgentsList = [{
        'name': 'EXT'
      },
      {
        'name': 'CLA'
      },
      {
        'name': 'VAL'
      }
    ]

  }

  /**
   * Request documents grouped by datamodel
   */
  private getDashboardCardsInfo() {

    let params = {
      start_date: this.formatDate(this.start_date),
      end_date: this.formatDate(this.end_date),
      tzoffset: -1 * new Date().getTimezoneOffset(),
    };

    this.apiService
      .get('overviewdashboard/dashboardinfo/', params, '')
      .subscribe(data => {
        this.pagesProcessed = data.pages_processed
        this.documentsProcessed = data.documents_processed
        this.processedByEXT = data.documents_extraction
        this.processedByCLA = data.documents_classification
        this.processedByVAL = data.documents_validation
      });
  }

  private getDocumentsError() {
    this.errorNumberByMonths = []
    let params = {
      lastMonths: 12,
      start_date: this.formatDate(this.start_date),
      end_date: this.formatDate(this.end_date),
      tzoffset: -1 * new Date().getTimezoneOffset(),
    };

    this.apiService
      .get('overviewdashboard/documenterrornumbers/', params, '')
      .subscribe(data => {
        this.errorNumberByMonths = data
      });
  }

  private getValidationErrorTypes() {

    let params = {
      start_date: this.formatDate(this.start_date),
      end_date: this.formatDate(this.end_date),
      tzoffset: -1 * new Date().getTimezoneOffset(),
    };


    this.apiService
      .get('overviewdashboard/validationerrornumbers/', params, '')
      .subscribe(data => {
        this.validationErrorTypesData = data;
        this.validationErrorTypesTotalCount = data.reduce((acc, item) => acc + item.count, 0);

        // Añadir una propiedad 'percentage' a cada objeto basada en el totalCount
        this.validationErrorTypesData.forEach((item, index) => {
          item.percentage = (item.count / this.validationErrorTypesTotalCount) * 100;
          item.class = this.chartClasses[index % this.chartClasses.length];
        });

      });
  }

  private getDocumentsTableInfo() {

    let params = {
      start_date: this.formatDate(this.start_date),
      end_date: this.formatDate(this.end_date),
      tzoffset: -1 * new Date().getTimezoneOffset(),
    };

    this.apiService
      .get('overviewdashboard/documentsinfo/', {
        ...params,
        ...this.filters,
        page: this.pagination.page
      }, '')
      .subscribe(data => {

        let dataObject = {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          data: data.data
        };
        this.rows = dataObject;
        this.documentsLoading = false;

        this.pagination = this.paginationService.setPagination('documents', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });

        this.dataLoading = false;
        this.dateSearch = false;
      });
  }

  private getStatusData() {
    this.statusData = []
    let params = {
      start_date: this.formatDate(this.start_date),
      end_date: this.formatDate(this.end_date),
      tzoffset: -1 * new Date().getTimezoneOffset(),
    };

    this.apiService
      .get('clientoverview/status/', params, '')
      .subscribe(data => {
        this.statusData = data
      });
  }

  private getValidationErrorsList() {
    this.validationErrorsList = this.globalService.getErrorsMapping();
  }

  /**
   * Request documents grouped by datamodel
   */
  private getDocumentsByDatamodel() {
    let params = {
      start_date: this.formatDate(this.start_date),
      end_date: this.formatDate(this.end_date),
      tzoffset: -1 * new Date().getTimezoneOffset(),
    };

    this.apiService
      .get('clientoverview/datamodels/', params, '')
      .subscribe(data => {

        data = data
        .sort((a, b) => b.count - a.count) // Sort by count in descending order
        .slice(0, 5);

        this.documentTypesData = data;
        this.documentTypesTotalCount = data.reduce((acc, item) => acc + item.count, 0);

        // Añadir una propiedad 'percentage' a cada objeto basada en el totalCount
        this.documentTypesData.forEach((item, index) => {
          item.percentage = (item.count / this.documentTypesTotalCount) * 100;
          item.class = this.chartClasses[index % this.chartClasses.length];
        });
      });
  }

  public formatDate(date) {
    return moment(new Date(date)).format('YYYY-MM-DD');
  }

  /**
   * Return loaded columns with the information of each column of
   * the table we want to show
   */
  public getColumns() {
    this.columns = [{
        header: 'overviewDashboard.documentName',
        name: 'documentDisplayName',
        type: 'link',
        clickLink: this.goToDocument.bind(this),
        title: 'documentdisplayname',
      },
      {
        header: 'overviewDashboard.datamodel',
        type: 'default',
        name: 'datamodelDisplayName',
        title: 'datamodelDisplayName',
      },
      {
        header: 'overviewDashboard.aiAgents',
        type: 'simple-tags',
        name: 'aiAgents',
      },
      {
        header: 'overviewDashboard.status',
        name: 'statusDisplayName',
        type: 'one-span-status',
        title: 'statusDisplayName'
      },
      {
        header: 'overviewDashboard.validationErrorTypes',
        name: 'validationErrorTypes',
        tags: this.getTagsIcon.bind(this),
        type: 'tags',
        orderBy: 'errorName',
        class: ''
      },
      {
        header: 'overviewDashboard.date',
        name: 'createdDate',
        type: 'date',
        format: 'lll',
        title: 'createdDate'
      },
    ];
  }

  public getSettingsTable() {
    this.settingTable = {
      'dataId': 'documentId',
      'hasSelect': 'false',
      'getDataFromDB': this.getDocumentsTableInfo.bind(this),
      'responsiveTitle': {
        'label': 'documentDisplayName',
        'value': 'documentDisplayName',
      },
      'paginationClass': 'relative-pag'
    }
  }


  /**
   * Return CSS Class for One tag icon or some tags icon
   */
  public getTagsIcon(tags: any) {
    return tags.length > 1 ?
      'fa fa-tags' :
      tags.length === 1 ?
      'fa fa-tag' :
      '';
  }

  /**
   * Handle loading of the table from the child
   */
  public onLoadingChange(bool) {
    this.documentsLoading = bool;
  }

  /**
   * Input date filter handler
   * Called when general datepicker changed
   */
  public onInputDatesSelect() {
    this.dateSearch = true
    // Transform Moment.js date to regular date object
    this.start_date = new Date(this.start_date);
    this.end_date = new Date(this.end_date);

    this.getDashboardCardsInfo()
    this.getDocumentsError()
    this.getValidationErrorTypes()
    this.getStatusData()
    this.getDocumentsByDatamodel()
    this.getDocumentsTableInfo()
  }

  /**
   * Go to the project with the project information pass by params
   */
  public goToDocument(document, event = null) {
    if (event) {
      event.stopPropagation();
    }

    this.linkService.goToDocument(document, true);
  }

  /**
   * Variable to show the filter panel or not
   */
  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  public onSearch(filters) {
    this.documentsLoading = true;

    this.pagination = this.paginationService.getDefaultPagination('documents');
    this.filters = { ...this.filters,
      documentdisplayname: filters['documentdisplayname'] != undefined ?
        filters['documentdisplayname'] :
        '',
      datamodelid: filters['datamodelId'] != undefined ? filters['datamodelId'] : '',
      statusid: filters['statusId'] != undefined ? filters['statusId'] : '',
      validationErrorTypes: filters['validationErrorTypes'] != undefined ?
        filters['validationErrorTypes'] :
        '',
      aiAgents: filters['aiAgents'] != undefined ?
        filters['aiAgents'] :
        '',
    };
    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        switch (f) {
          case 'datamodelid':
            return {
              name: 'datamodelname', value: filters['datamodelname']
            };
          case 'statusid':
            return {
              name: 'status_name', value: filters['status_name']
            };
          case 'documentdisplayname':
            return {
              name: 'documentdisplayname', value: filters['documentdisplayname']
            };
          case 'validationErrorTypes':
            return {
              name: 'validationErrorTypeName', value: filters['validationErrorTypeName']
            };
          default:
            return {
              name: f, value: this.filters[f]
            };
        }
      });

    this.getDocumentsTableInfo()
  }

  public cleanIndividualFilter(filter: string) {
    this.labelFilters = this.labelFilters.filter(f => f.name !== filter);

    if (filter === 'datamodelname') {
      filter = 'datamodelid'
    }

    if (filter === 'status_name') {
      filter = 'statusid'
    }

    if (filter === 'validationErrorTypeName') {
      filter = 'validationErrorTypes'
    }

    this.filterEvent.next(filter)
    delete this.filters[filter];

    if (filter === 'documentdisplayname') {
      this.filters['documentdisplayname'] = '';
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'documentdisplayname');
    }

    this.documentsLoading = true;
    this.getDocumentsTableInfo();
  }

  public filterTextValue(logic: string) {
    switch (logic) {
      case 'or':
        return this.translate.transform('documentFilter.or');
      case 'and':
        return this.translate.transform('documentFilter.and');
      case 'phrase':
        return this.translate.transform('documentFilter.phrase');
      case 'starts_with':
        return this.translate.transform('documentFilter.startsWith');
      case 'ends_with':
        return this.translate.transform('documentFilter.endsWith');
      default:
        return logic;
    }
  }

  public onClickValidationError(val) {
    this.documentsLoading = true;
    if (!Boolean(this.showFilters)) {
      this.toggleShowFilters()
    }
    this.selectValidationFilter = true;
    this.validationNameFilter = val.validationErrorName;

    let errorid = this.validationErrorsList.filter(validation => validation.errorname == val.validationErrorName)[0].errorid
    if (Boolean(this.filters['validationErrorTypes'])) {
      if (this.filters['validationErrorTypes'].includes(errorid)) {
        delete this.filters['validationErrorTypes']
        this.selectValidationFilter = false
        this.validationNameFilter = ''    
      } else {
        this.filters = { ...this.filters, 
          validationErrorTypes: [errorid],
        }; 
      }
    } else {
      this.filters = { ...this.filters, 
        validationErrorTypes: [this.validationErrorsList.filter(validation => validation.errorname == val.validationErrorName)[0].errorid],
      };  
    }

    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        switch (f) {
          case 'validationErrorTypes':
            return {
              name: 'validationErrorTypeName', value: this.validationErrorsList.filter(validation => this.filters['validationErrorTypes'].includes(validation.errorid))[0].errorname
            };
          case 'datamodelid':
            return {
              name: 'datamodelname', value: this.datamodelList.filter(el => el.datamodelid == this.filters['datamodelid'])[0].datamodeldisplayname
            };
          case 'statusid':
            return {
              name: 'status_name', value: this.statusList.filter(el => el.statusid == this.filters['statusid'])[0].statusname
            };
          case 'documentdisplayname':
            return {
              name: 'documentdisplayname', value: this.filters['documentdisplayname']
            };
          default:
            return {
              name: f, value: this.filters[f]
            };
        }
      });

    this.getDocumentsTableInfo()
    
  }

  public onClickDatamodel(datamodel) {
    this.documentsLoading = true;
    this.selectDatamodelFilter = true
    this.datamodelNameFilter = datamodel.datamodelname
    if (!Boolean(this.showFilters)) {
      this.toggleShowFilters()
    }
    
    if (Boolean(this.filters['datamodelid'])) {
      if (this.filters['datamodelid'] == datamodel.datamodelid) {
        delete this.filters['datamodelid']
        this.selectDatamodelFilter = false
        this.datamodelNameFilter = ''    
      } else {
        this.filters = { ...this.filters, 
          datamodelid: datamodel.datamodelid,
        };
      }
    } else {
      this.filters = { ...this.filters, 
        datamodelid: datamodel.datamodelid,
      };
    }

    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        switch (f) {
          case 'datamodelid':
            return {
              name: 'datamodelname', value: datamodel.datamodelname
            };
          case 'statusid':
            return {
              name: 'status_name', value: this.statusList.filter(el => el.statusid == this.filters['statusid'])[0].statusname
            };
          case 'documentdisplayname':
            return {
              name: 'documentdisplayname', value: this.filters['documentdisplayname']
            };
          case 'validationErrorTypes':
            return {
              name: 'validationErrorTypeName', value: this.validationErrorsList.filter(validation => this.filters['validationErrorTypes'].includes(validation.errorid))[0].errorname
            };
          default:
            return {
              name: f, value: this.filters[f]
            };
        }
      });

    this.getDocumentsTableInfo()
    
  }

  public onClickAgent(aiAgent) {
    this.documentsLoading = true;
    this.selectedAnyAgentFilter = true;
    if (!Boolean(this.showFilters)) {
      this.toggleShowFilters()
    }

    switch(aiAgent) {
      case 'VAL':
        if (Boolean(this.filters['aiAgents'])) {
          if (this.filters['aiAgents'].includes(aiAgent)) {
            this.selectedFilterVal = true;
          } else {
            this.selectedFilterVal = false;
          }
        } else {
          this.selectedFilterVal = false;
        }
        this.selectedFilterExt = true
        this.selectedFilterCla = true
        break;
      case 'EXT':
        if (Boolean(this.filters['aiAgents'])) {
          if (this.filters['aiAgents'].includes(aiAgent)) {
            this.selectedFilterExt = true;
          } else {
            this.selectedFilterExt = false;
          }
        } else {
          this.selectedFilterExt = false;
        }        
        this.selectedFilterVal = true
        this.selectedFilterCla = true
        break;
      case 'CLA':
        if (Boolean(this.filters['aiAgents'])) {
          if (this.filters['aiAgents'].includes(aiAgent)) {
            this.selectedFilterCla = true;
          } else {
            this.selectedFilterCla = false;
          }
        } else {
          this.selectedFilterCla = false;
        }
        this.selectedFilterExt = true
        this.selectedFilterVal = true
        break;
      default:
        break;
    }

    if (Boolean(this.filters['aiAgents'])) {
      if (this.filters['aiAgents'].includes(aiAgent)) {
        delete this.filters['aiAgents']
        this.selectedFilterExt = false;
        this.selectedFilterVal = false;
        this.selectedFilterCla = false;    
      } else {
        this.filters = { ...this.filters, 
          aiAgents: [aiAgent],
        };  
      }
    } else {
      this.filters = { ...this.filters, 
        aiAgents: [aiAgent],
      }; 
    }

    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        switch (f) {
          case 'datamodelid':
            return {
              name: 'datamodelname', value: this.datamodelList.filter(el => el.datamodelid == this.filters['datamodelid'])[0].datamodeldisplayname
            };
          case 'statusid':
            return {
              name: 'status_name', value: this.statusList.filter(el => el.statusid == this.filters['statusid'])[0].statusname
            };
          case 'documentdisplayname':
            return {
              name: 'documentdisplayname', value: this.filters['documentdisplayname']
            };
          case 'validationErrorTypes':
            return {
              name: 'validationErrorTypeName', value: this.validationErrorsList.filter(validation => this.filters['validationErrorTypes'].includes(validation.errorid))[0].errorname
            };
          default:
            return {
              name: f, value: this.filters[f]
            };
        }
      });

    this.getDocumentsTableInfo()
    
  }

  public onClickStatus(status) {
    this.documentsLoading = true;
    if (!Boolean(this.showFilters)) {
      this.toggleShowFilters()
    }
    let statusId = this.statusList.filter((sta) => sta.statusdisplayname === status)[0].statusid;

    if (Boolean(this.filters['statusid'])) {
      if (this.filters['statusid'] == statusId) {
        delete this.filters['statusid']
      } else {
        this.filters = { ...this.filters,
          statusid: statusId,
        };
      }
    } else {
      this.filters = { ...this.filters,
        statusid: statusId,
      };
    }


    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        switch (f) {
          case 'statusid':
            return {
              name: 'status_name', value: this.statusList.filter(el => el.statusid == this.filters['statusid'])[0].statusname
            };
          case 'datamodelid':
            return {
              name: 'datamodelname', value: this.datamodelList.filter(el => el.datamodelid == this.filters['datamodelid'])[0].datamodeldisplayname
            };
          case 'documentdisplayname':
            return {
              name: 'documentdisplayname', value: this.filters['documentdisplayname']
            };
          case 'validationErrorTypes':
            return {
              name: 'validationErrorTypeName', value: this.validationErrorsList.filter(validation => this.filters['validationErrorTypes'].includes(validation.errorid))[0].errorname
            };
          default:
            return {
              name: f, value: this.filters[f]
            };
        }
      });

    this.getDocumentsTableInfo()
  }

  public checkSelectedForFilter(el, value) {
    if (Boolean(this.filters[el])) {
      if (Array.isArray(this.filters[el]) || typeof this.filters[el] === 'string') {
        if (el == 'aiAgents') {
          switch(value) {
            case 'VAL':
              this.selectedFilterExt = true;
              this.selectedFilterCla = true;
              this.selectedFilterVal = false;
              break;
            case 'EXT':
              this.selectedFilterCla = true;
              this.selectedFilterVal = true;
              this.selectedFilterExt = false;
              break;
            case 'CLA':
              this.selectedFilterExt = true;
              this.selectedFilterVal = true;
              this.selectedFilterCla = false;
              break;
            default:
              break;
          }
        }
      }

      if (typeof this.filters[el] === 'number') {
        return this.filters[el] === value;
      }
    } else {
      return false
    }
  }

  getProgressBarClass(documentType: any): string {
    if (Boolean(this.filters['datamodelid'])) {
      if (!this.selectDatamodelFilter || this.datamodelNameFilter === documentType.datamodelname) {
        return documentType.class;
      } else {
        return 'bg-secondary';
      }
    } else if (this.selectDatamodelFilter) {
      return 'bg-secondary';
    } else {
      return documentType.class;
    }
  }

  getProgressBarClassValidation(validationError: any): string {
    let errorid = this.validationErrorsList.filter(val => val.errorname === validationError.validationErrorName)[0].errorid;
    if (Boolean(this.filters['validationErrorTypes'])) {
      if (!this.selectValidationFilter || this.filters['validationErrorTypes'].includes(errorid)) {
        return validationError.class;
      } else {
        return 'bg-secondary';
      }
    } else if (this.selectValidationFilter) {
      return 'bg-secondary';
    } else {
      return validationError.class;
    }
  }

  public onCleanFilters(e) {
    this.selectedFilterExt = false;
    this.selectedFilterVal = false;
    this.selectedFilterCla = false;
    this.selectDatamodelFilter = false
    this.datamodelNameFilter = ''
    this.selectValidationFilter = false
    this.validationNameFilter = ''
  }
}