import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { UpdateDatamodelModalComponent } from 'src/app/components/modals/update-document-datamodel/update-document-datamodel.component';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { UpdateDocsComponent } from 'src/app/components/modals/update-docs/update-docs.component';
import { PagesSelection } from 'src/app/components/modals/update-children/pages-selection';

/**
 * Request to change document pages.
 */
export interface ChangeDocumentRequest {
  editDocument: boolean;
}

@Component({
  selector: 'app-document-info',
  templateUrl: './document-info.component.html',
  styleUrls: ['./document-info.component.scss']
})
export class DocumentInfoComponent implements OnInit, OnDestroy {
  @Input() documentDetails: any;

  @Output() createdPagesSelection = new EventEmitter<PagesSelection>();
  @Output() documentChanged = new EventEmitter<void>();

  public isLoading: boolean = false;
  private datamodels: any[];
  private approval: any = {};
  private reviewersGroupsTypes: any[];
  private subscriptionReviewers: Subscription;
  private subscriptionDatamodels: Subscription;
  private user: any = {};
  public _userPermissions: any;
  public showReviewFlow: boolean = false;
  public hasEnrichmentModule: boolean;
  public changeDocumentRequest: ChangeDocumentRequest;
  public showWordMock: boolean = false;

  constructor(
    private modalService: NgbModal,
    private globalService: GlobalService,
    private apiService: ApiService,
    private alertService: AlertService,
    private translate: TranslatePipe,
    public fileDownloadService: FileDownloadService,
    private authService: AuthenticationService,
    public documentRenderService: DocumentRenderService
  ) {
    this.user = authService.getLoggedInUser();
    this._userPermissions = this.globalService.getUserPermissionsConst();
    this.showReviewFlow = this.authService.userCanViewModule(
      this.user,
      'DueTimeReviewFlowFunctionality'
    );
    this.hasEnrichmentModule = this.authService.userCanViewModule(
      this.user,
      'EnrichmentCoreFunctionality'
    );
    this.showWordMock = this.authService.userCanViewModule(this.user,'DemoModule');
  }

  ngOnInit() {
    this.setApproval();
    let datamodels = this.globalService.getDatamodels();
    if (
      Array.from(datamodels.keys()).length === 0 &&
      !this.globalService.passedWatcherDatamodels
    ) {
      this.isLoading = true;
      this.subscriptionDatamodels = this.globalService
        .watchDataModels()
        .subscribe(() => {
          datamodels = this.globalService.getDatamodels();
          this.datamodels = Array.from(datamodels.keys()).map(key => ({
            datamodelid: key,
            datamodeldisplayname: datamodels.get(key)['datamodeldisplayname']
          }));
          this.isLoading = false;
        });
    } else {
      this.datamodels = Array.from(datamodels.keys()).map(key => ({
        datamodelid: key,
        datamodeldisplayname: datamodels.get(key)['datamodeldisplayname']
      }));
    }
  }

  /**
   * Launches a datamodel change request (with no actual change) to re-run the document.
   *
   * A warning modal is show previusly.
   */
  public rerunDocument() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const modalWindowRef = this.modalService.open(
      ConfirmModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      message: this.translate.transform('documentInfo.rerunMessage')
    };
    modalWindowRef.result.then(result => {
      if (result > 0) {
        const params = {
          datamodelid: this.documentDetails.datamodel.datamodelid,
          documentid: this.documentDetails.documentid,
          singledocnew: false
        };
        this.isLoading = true;
        this.apiService
          .post('documents/changedatamodel', params, '')
          .subscribe(data => {
            this.isLoading = false;
            const datamodel = this.datamodels.find(
              d => d.datamodelid === this.documentDetails.datamodel.datamodelid
            );
            this.documentDetails.datamodel = {
              datamodelid: this.documentDetails.datamodel.datamodelid,
              datamodeldisplayname: datamodel.datamodeldisplayname
            };
            this.alertService.success(
              this.translate.transform('documentInfo.rerunComplete'),
              true
            );
            setTimeout(()=>{
              window.location.reload()
            }, 500)
          });
      }
    });
  }

  /**
   *  Launches a modal to allow the user to change the datamodel.
   */
  public launchChangeDatamodelModal() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const modalWindowRef = this.modalService.open(
      UpdateDatamodelModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      document: this.documentDetails
    };
    modalWindowRef.result.then(result => {
      if (result > 0) {
        const params = {
          datamodelid: result,
          documentid: this.documentDetails.documentid
        };
        this.isLoading = true;
        this.apiService
          .post('documents/changedatamodel', params, '')
          .subscribe(data => {
            this.isLoading = false;
            const datamodel = this.datamodels.find(
              d => d.datamodelid === result
            );
            this.documentDetails.datamodel = {
              datamodelid: result,
              datamodeldisplayname: datamodel.datamodeldisplayname
            };
            this.alertService.success(
              this.translate.transform('documentInfo.datamodelChanged'),
              true
            );
          });
      }
    });
  }

  /**
   * Set approval and check if user is the assigned reviewer.
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals.find(
      a => a.currentapproval === true
    );
  }

  /**
   * Check if user is the assigned reviewer.
   */
  public approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid === this.user.userid
    );
  }

  /**
   * Gets the reviewers group types from the global service and sets the approval according to them.
   */
  private getReviewersTypes() {
    this.reviewersGroupsTypes = this.globalService.getReviewers();
    if (
      this.reviewersGroupsTypes.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.subscriptionReviewers = this.globalService
        .watchUtils()
        .subscribe(() => {
          this.reviewersGroupsTypes = this.globalService.getReviewers();
          this.setApproval();
        });
    } else {
      this.setApproval();
    }
  }

  ngOnDestroy() {
    // Remove subscriptions
    if (this.subscriptionReviewers) this.subscriptionReviewers.unsubscribe();
    if (this.subscriptionDatamodels) this.subscriptionDatamodels.unsubscribe();
  }

  /**
   * Enrichment files upload handler
   */
  public onUpdateDocs() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options = {
      level: 'document',
      projectId: this.documentDetails.project.projectid,
      projectName: this.documentDetails.project.projectname,
      analysisId: this.documentDetails.analysis.analysisid,
      analysisName: this.documentDetails.analysis.analysisname,
      documentId: this.documentDetails.documentid
    };

    const modalWindowRef = this.modalService.open(
      UpdateDocsComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.settings = options;
    modalWindowRef.result.then(formValues => {});
  }

  /**
   * Called when user clicks on 'split document'
   * or 'edit document' button.
   * @param editDocument if true, the current document is edited
   * instead of creating new one.
   */
  onRequestChangeDocument(editDocument: boolean): void {
    if (
      this.changeDocumentRequest &&
      this.changeDocumentRequest.editDocument == editDocument
    ) {
      this.cancelChangeDocument();
    } else {
      this.changeDocumentRequest = {
        editDocument
      };
    }
  }

  /**
   * Cancel editing or splitting document.
   */
  public cancelChangeDocument() {
    this.changeDocumentRequest = undefined;
    this.createdPagesSelection.emit(undefined);
  }

  /**
   * Called when a document was edited or splitted.
   */
  public onDocumentChanged(): void {
    this.documentChanged.emit();
    this.changeDocumentRequest = undefined;
    this.createdPagesSelection.emit(undefined);
  }

  /*Function to check if the 'Re-run document' button have to appear */
  public checkRerunnability(): boolean{
    if(this.documentDetails.parentdocumentdatamodel !== undefined){
      if(!this.documentDetails.isparentdocument && this.documentDetails.parentdocumentdatamodel['datamodel'].iselectronic){
        return false;
      }
    }
    return true;
  }
}
