import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup
} from '@angular/forms';
import {
  TrainableService
} from '../../trainable.service';
import {
  TranslatePipe
} from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-trainable-multiple-body',
  templateUrl: './trainable-multiple-body.component.html',
  styleUrls: ['./trainable-multiple-body.component.scss']
})

export class TrainableMultipleBodyComponent implements OnInit {

  @Input() public multiple: FormArray;
  @Input('genericDatamodel') public genericDatamodel: boolean;
  @Input('isCreating') public isCreating: boolean;
  @Input('field') public field: FormControl;
  @Input('index') public index: number;
  @Input('datamodelProcessType') public datamodelProcessType: String;
  @Input('datamodelLang') public datamodelLang: String;

  public isOpen: boolean = false

  public isCollapsed: boolean = true;

  constructor(
    private translate: TranslatePipe,
    public trainableService: TrainableService
  ) {}

  ngOnInit() {}

  public getFieldName(value) {
    return value ?
      value :
      this.translate.transform('trainable.individual.newField');
  }

  /**
   * Toggle field expanded
   * @param index field index number
   */
  public toggleExpandedField(index: number) {
    this.isOpen = !this.isOpen;
  }

  
  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  public getQuestion() {
    switch (this.datamodelLang) {
      case 'French':
        return 'Quest-ce que la ' + this.getFieldName(this.field.get('fieldofinterestname').value) + ' des documents?'    
      case 'Spanish':
        return'¿Cual es el ' + this.getFieldName(this.field.get('fieldofinterestname').value) + ' de el documento?'    
      case 'German':
        return 'Was ist die ' + this.getFieldName(this.field.get('fieldofinterestname').value) + ' des Dokuments?'       
      default:
        return 'What is the ' + this.getFieldName(this.field.get('fieldofinterestname').value) + ' of the document?'   
    }
  }
}
