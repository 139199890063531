import { Component, AfterViewInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-curve-chart',
  templateUrl: './curve-chart.component.html',
  styleUrls: ['./curve-chart.component.scss']
})
export class CurveChartComponent implements AfterViewInit {
  @ViewChild('curveChart') curveChart!: ElementRef;
  @Input('data') data: any;

  public months = ['January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December']
  public counts: any
  public rangeType: any;
    
  constructor() { }
  
  ngOnInit(): void {
    // Inicialización adicional si es necesario
      this.months = this.data.map(item => item.month);
      this.counts = this.data.map(item => item.count);
  }

  ngAfterViewInit(): void {
    const ctx = this.curveChart.nativeElement.getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.data.map(item => {
          if (item.month) {
            return item.month
          } else if (item.week) {
            return item.week;
          } else {
            return item.day
          }
        }),
        datasets: [{
          label: 'Documents with failed validations per month',
          data: this.counts,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: '#007bff',
          borderWidth: 4,
          tension: 0.4,
          fill: false,
          pointBackgroundColor: 'white',
          pointBorderColor: 'black',
          pointBorderWidth: 1,
          pointRadius: 6,
          pointHoverRadius: 8,
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              fontStyle: 'bold',
              beginAtZero: true, // Asegurarse de que empiece en 0
              callback: (value) => value.toLocaleString() // Formatear valores con separadores de miles
            },
            gridLines: {
              color: '#00000033',
            }
          }],
          xAxes: [{
            ticks: {
              fontStyle: 'bold' 
            },
            gridLines: {
              color: '#00000033', 
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const value = dataset.data[tooltipItem.index].toLocaleString(); // Formatear con separadores de miles
              const label = data.labels[tooltipItem.index];
              return `${label}: ${value}`; // Mostrar valor formateado en el tooltip
            }
          }
        }
      }
    });
  }
}
